<template>
  <HomeTamplate>
    <div class="columns flex-wrap" >
      <div
        class="column is-12 "
        :class="maxWidth > 1170 ? 'has-text-right' : 'has-text-centered'"
      >
        <a :href="webAvocado"><img src="@/assets/logoAvocado.png" /></a>
      </div>
      <div class="column has-text-left is-9 mg-x-auto">
        <h1 class="is-size-3 has-text-centered mg-b-5 text-color-primary">
          เข้าสู่ระบบ
        </h1>
        <div  class="is-flex flex-js-center mg-b-3">
          <div
            @click="signinWithFaceBook()"
            class="box-signin-socail mg-x-2"
            style="background-color: #0065b3"
          >
            <i class="fab fa-facebook-f color-white"></i>
          </div>

          <div
            @click="signinWithGoogle()"
            class="box-signin-socail mg-x-2"
            style="background-color: #F1F1F1"
          >
            <img  :src="require('@/assets/google.png')" class="logo-google" />
          </div>
        </div>
        <div
          class="boxError th mg-b-5"
          :class="{
            'animate__shakeX animate__animated': animated,
            boxSuccess: !textError,
          }"
          @animationend="animated = false"
          v-if="textError"
        >
          {{ textError }}
        </div>
        <form @submit.prevent="onSubmit(form)">
          <b-field label="อีเมล">
            <b-input rounded v-model="form.email"></b-input>
          </b-field>
          <b-field label="รหัสผ่าน">
            <b-input
              rounded
              type="password"
              @key.enter="onSubmit(form)"
              v-model="form.password"
              password-reveal
            ></b-input>
          </b-field>
          <div class="has-text-right">
            <router-link to="/forgot-password">
              ลืมรหัสผ่าน
            </router-link>
          </div>
          <div
            class="column is-6  mg-y-3 mg-x-auto "
            :class="maxWidth > 1170 ? '' : 'is-paddingless'"
          >
            <div class="buttons">
              <button
                type="submit"
                class="pd-y-5 bt-color-avocado is-rounded w-100 button is-info"
                rounded
                expanded
              >
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="column is-12 5">
        <div class="has-text-centered">
          <p>
            คุณยังไม่มีบัญชี?
            <router-link to="/sign-up">สมัครสมาชิก</router-link>
          </p>
        </div>
      </div>
      <!-- <GoogleLogin
        :params="params"
        :renderParams="renderParams"
        :onSuccess="onSuccess"
        :onFailure="onFailure"
      ></GoogleLogin> -->
      <b-modal :active.sync="isProfile" :width="640">
        <Profile @closeProfileModal="closeProfileModal" />
      </b-modal>
    </div>
  </HomeTamplate>
</template>
<script>
import Profile from "@/components/auth/Profile";
import { HTTP } from "@/service/axios";
import HomeTamplate from "@/template/HomeTemplate";
// import GoogleLogin from "vue-google-login";
export default {
  components: {
    HomeTamplate,
    Profile,
    // GoogleLogin,
  },
  data() {
    return {
      params: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      isProfile: false,
      form: {
        email: "",
        password: "",
      },
      maxWidth: window.innerWidth,
      textError: "",
      animated: false,
      windowHeight: window.innerHeight,
      webAvocado: process.env.VUE_APP_WEB_AVOCADO,
    };
  },
  methods: {
    //Pw8280800@
    // onSuccess(googleUser) {
    //   console.log(googleUser);

    //   // This only gets the user information: id, name, imageUrl and email
    //   console.log(googleUser.getBasicProfile());
    // },
    // onFailure(googleUser) {
    //   console.log(googleUser);

    //   // This only gets the user information: id, name, imageUrl and email
    //   console.log(googleUser.getBasicProfile());
    // },
    signout() {
      this.$store.commit("resetState");
      this.$router.push("/sign-in");
    },
    closeProfileModal(direct = false) {
      this.isProfile = false;
      if (direct) {
        this.$router.push("/canvas");
      } else {
        this.signout();
      }
    },
    onSubmit(data) {
      HTTP.post("sign/in",data)
        .then((res) => {
          if (res.data.success === false) {
            this.animated = true;
            if (res.data.data == 'wrong password' && res.data.obj.fromType == "google") {
              this.textError = "บัญชีนี้ถูกใช้งานโดยวิธีอื่น กรุณาลองใหม่";
            }else{
              this.textError = "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง";
            }
           
          } else {
            this.textError = null;
            this.animated = false;
            if (
              res.data.obj.name != "" &&
              res.data.obj.surname != "" &&
              res.data.obj.telephoneNumber != "" &&
              res.data.obj.position != "" &&
              res.data.obj.organizeName != ""
            ) {
              this.$store.commit("setRole", 1);
              this.$store.commit("setToken", res.data.obj.token);
              this.$store.commit("setUser", res.data.obj);
              this.$router.push("/canvas");
            } else {
              this.$store.commit("setToken", res.data.obj.token);
              this.$store.commit("setUser", res.data.obj);
              this.isProfile = true;
            }
          }
        })
        .catch((e) => {
          console.log("/api/user/signin/error", e);
          if (!e.response || e.response != undefined) {
            // network error
            this.textError =
              "Cannot connect to server. Please contact the system administrator.";
            if (e.response.status === 404) {
              this.textError = "Server in maintenance";
            } else if (e.response.status === 405) {
              this.textError =
                "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง";
            } else {
              this.textError = e.response.data.message;
            }
          } else {
            this.textError = e.response.data.message;
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.textError = null;
            this.animated = false;
          }, 4000);
        });
    },
    async signinWithGoogle() {
        var auth2 = gapi.auth2.getAuthInstance();
				auth2.signIn().then(async (res) => {
					let profile = auth2.currentUser.get().getBasicProfile();

					let password = auth2.currentUser.get().getAuthResponse().access_token;

					let email = "";
					let name = "";
					let lastName = "";

					Object.keys(profile).forEach((key, index) => {

            if (index == 5) {
              email = profile[key];
            }

            if (index == 2) {
              name = profile[key];
            }

            if (index == 3) {
              lastName = profile[key];
            }
					});

					let haveEmail = await this.haveEmail(email);
     
					if (haveEmail) {

            let jwt = require('jsonwebtoken');
            let secret = 'AvdFvsdd125ssEX@'
            let token = jwt.sign({email: email}, secret);

            let obj = {
              token: token,
              password: password,
            };

            await HTTP.post(`update/password`, obj).then((res) => {
              if (res.data.success) {
                this.onSubmit({
                  email: email,
                  password: password,
                });
              } else {
                this.$buefy.toast.open({
                  duration: 3000,
                  message: 'เข้าสู่ระบบไม่สำเร็จกรุณาลองใหม่',
                  type: 'is-danger'
                })
              }
            });
					} else {
            let obj = {
              userId:'',
              name:name,
              surname:lastName,
              telephoneNumber:'',
              position:'',
              organizeName:'',
              email:email,
              password:password,
              confirmPassword:password,
              fromType: "google"
            }

					  this.signup(obj);
					}
				});
    },
    async signinWithFaceBook() {
      FB.login(
        (responseLogin) => {

          if (responseLogin.authResponse) {
            FB.api(
              "/me",
              "GET",
              { fields: "id,first_name,last_name,email" },
              async (response) => {
                let haveEmail = await this.haveEmail(response.email);
                let password = responseLogin.authResponse.accessToken;

                if (haveEmail) {
                  let jwt = require('jsonwebtoken');
                  let secret = 'AvdFvsdd125ssEX@'
                  let token = jwt.sign({email: response.email}, secret);

                  let obj = {
                    token: token,
                    password: password,
                  };
                  await HTTP.post(`update/password`, obj).then((res) => {
                    if (res.data.success) {
                      this.onSubmit( {
                        email: response.email,
                        password: password,
                      })
                    } else {
                      this.$buefy.toast.open({
                        duration: 3000,
                        message: 'เข้าสู่ระบบไม่สำเร็จกรุณาลองใหม่',
                        type: 'is-danger'
                      })
                    }
                  });

                } else {
                   let obj = {
                    userId:'',
                    name:response.first_name,
                    surname:response.last_name,
                    telephoneNumber:'',
                    position:'',
                    organizeName:'',
                    email:response.email,
                    password:password,
                    confirmPassword:password,
                    fromType: "faceBook"
                  }

                  this.signup(obj);
                }
              }
            );
          }
        },
        { scope: "email" }
      );
    },
    signup(data){
      HTTP.post('/sign/up',data).then(res=>{
      if(res.data.success){
        this.onSubmit( {
          email: data.email,
          password: data.password,
        })
      }else if(res.data.data =="email is not work"){
        this.$buefy.toast.open({
          duration: 3000,
          message: 'อีเมลนี้ถูกใช้งานแล้ว',
          type: 'is-danger'
        })
      }else{
        this.$buefy.toast.open({
          duration: 3000,
          message: res.data.data+' กรุณาติดต่อผู้ดูแล',
          type: 'is-danger'
        })
      }
      }).catch(e=>{
        console.log('/sign/up error',e)
      })
    },
    async haveEmail(email) {
      let have = false;
      let obj = {
        email: email,
      };

      await HTTP.post(`check/email`, obj).then((res) => {

        if (res.data.success) {
          have = false;
        } else {
          have = true;
        }
      });

      return have;
    },
  },
};
</script>
<style scoped>
.boxError {
  border: 1px solid red;
  color: red;
  padding: 20px;
  text-align: center;
  background: #f7d0d0;
}
.box-signin-socail {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.color-white{
  color: #ffffff;
}
.logo-google{
  max-width: 25px;
}

</style>
